import React, { useState } from 'react';
import { ReactComponent as Logo } from '../../assets/GB-FOOTER.svg';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import './index.css';

const url = 'https://guavababa.us4.list-manage.com/subscribe/post?u=bec27b8ebb2a0bb1c42af0d53&id=316e6574e1';

function Footer() {

  const [email, setEmail] = useState('');

  return (
    <div className="footer-container">
      <div className="footer-left">
        <Logo className="footer-logo" />
      </div>
      <div className="footer-right">
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <div className="footer-inputs">
              {status === 'sending' && <div style={{ fontSize: '12px', marginRight: '8px'}}>Subscribing..</div>}
              {status === 'error' && <div style={{ fontSize: '12px', marginRight: '8px', color: 'red'}}>Error! Please retry.</div>}
              {status === 'success' && <div style={{ fontSize: '12px', marginRight: '8px'}}>Subscribed!</div>}
              <br />
              <input onChange={e => setEmail(e.target.value)} placeholder="email address" />
              <button onClick={() => subscribe({EMAIL: email})}>SUBSCRIBE</button>
            </div>
          )} />
      </div>
    </div>
  );

}

export default Footer;