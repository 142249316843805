import React, { useState, useEffect } from 'react';
import Item from '../Item';
import './index.css';

// import { shopItems } from '../items.js';

function ItemsList({ allItems }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (allItems) {
      setItems(allItems);
    }
  }, [allItems]);
  
  function renderItems() {
    return items.map(item => {
      return <Item key={item.handle} item={item} />
    })
  }

  return (
    <div className="items-container">
      <div className="items">
        {renderItems()}
      </div>
    </div>
  );
}

export default ItemsList;
