import React from 'react';
import './index.css';

export default function Home() {

  return (
    <div className="home-container">
      <div className="home-video">
        <iframe 
          className="video-iframe"
          src="https://player.vimeo.com/video/907294942?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
          frameborder="0" 
          allow="autoplay; fullscreen; picture-in-picture" 
          style={{display: 'block', width:'1140px'}} 
          title="GB STORIES vol. 1"></iframe>
      </div>
    </div>
  )
}