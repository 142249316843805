import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Link } from 'react-router-dom';
import './index.css';

function Cart({ client, checkout, handleCheckout }) {
  const [cart, setCart] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setIsLoading(true);
    setCart(checkout);
    setIsLoading(false);
    return () => {
      setCart(null);
    }
  }, [checkout]);

  function handleUpdate(id, qty, updateType) {
    let newQty = qty;
    if (updateType === '+') {
      newQty++;
    } else {
      newQty--;
    }
    const lineItemToUpdate = { id, quantity: newQty };
    const checkoutId = cart.id;

    if (newQty === 0) {
      const lineItemIdsToRemove = [id];
      client.checkout.removeLineItems(checkoutId, lineItemIdsToRemove).then(co => {
        setCart(co);
        handleCheckout(co);
      });
    } else {
      client.checkout.updateLineItems(checkoutId, lineItemToUpdate).then(co => {
        setCart(co);
        handleCheckout(co);
      });
    }
  }

  if (isLoading || !cart) {
    return (
      <div className="cart-container">
        <div className="cart">
          <h1 style={{textAlign: 'center'}}>Loading!</h1>
        </div>
      </div>
    )
  }

  function renderHeader() {
    if (width > 880) {
      return (
        <tr>
          <td>product</td>
          <td></td>
          <td align="right">price</td>
          <td align="right">qty</td>
          <td align="right">total</td>
        </tr>
      );
    } else {
      return (
        <tr>
          <td>product</td>
          <td align="center">price</td>
          <td align="center">qty</td>
          <td align="right">total</td>
        </tr>
      );
    }
  }

  function renderCartItems() {
    return cart.lineItems.map(item => {
      const price = item && item.variant && item.variant.price.amount 
        ? parseInt(item.variant.price.amount) 
        : 0;
      const quantity = item && item.quantity
        ? parseInt(item.quantity)
        : 0;
      const handle = item && item.variant && item.variant.product && item.variant.product.handle 
        ? item.variant.product.handle 
        : '';
      const size = item && item.variant && item.variant.title && (item.variant.title !== 'Default Title')
        ? ` – ${item.variant.title}`
        : '';

      if (width > 880) {
        return (
          <tr key={item.id} className="cart-row">
          <td className="row-image">
          <Link to={`/product/${handle}`}>
            <div 
              className="cart-image-container" 
              style={{backgroundImage: `url(${item.variant.image.src})`}}
              />
          </Link>
          </td>
          <td className="row-title">{item.title}{size}</td>
          <td align="right" className="row-price">${price}</td>
          <td align="right" className="row-quantity">
            <span style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleUpdate(item.id, quantity, '-')}> - </span>
              {quantity}
            <span style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleUpdate(item.id, quantity, '+')}> + </span>
          </td>
          <td align="right" className="row-total">${price * quantity}</td>
        </tr>
      )
      } else {
        return (
          <tr key={item.id} className="cart-row">
          <td align="left" className="row-title">
            <Link style={{ textDecoration: 'none', color: 'black' }} to={`/product/${handle}`}>{item.title}{size}</Link>
          </td>
          <td align="center" className="row-price">${price}</td>
          <td align="center" className="row-quantity">
            <span style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleUpdate(item.id, quantity, '-')}> - </span>
              {quantity}
            <span style={{ cursor: 'pointer', fontSize: '16px' }} onClick={() => handleUpdate(item.id, quantity, '+')}> + </span>
          </td>
          <td align="right" className="row-total">${price * quantity}</td>
        </tr>
      )
      }
    });
  }

  function renderCheckout() {
    if (cart) {
      if (cart.lineItems.length === 0) {
        return (
          <div>
            <h1 style={{textAlign: 'center'}}>Your cart is empty.</h1>
          </div>
        );
      } else {
        return (
          <>
            <div className="cart-title">SHOPPING CART</div>
            <table className="cart-table">
              <thead>
                {renderHeader()}
              </thead>
              <tbody>
                {renderCartItems()}
              </tbody>
            </table>
            <div className="cart-footer">
              <div className="footer-contents">
                <div className="subtotal-title">subtotal</div>
                <div className="subtotal-value">${parseInt(cart.subtotalPrice.amount)}</div>
                <div className="checkout-button-container">
                  <a href={cart.webUrl} className="checkout-button">CHECK OUT</a>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  }

  return(
    <div className="cart-container">
      <div className="cart">
        {renderCheckout()}
      </div>
      
    </div>
  );

}

export default Cart;