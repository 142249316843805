import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Nav from './components/Nav';
import Home from './components/Home';
import Footer from './components/Footer';
import ItemsList from './components/ItemsList';
import Info from './components/Info';
import Cart from './components/Cart';
import ItemDetail from './components/ItemDetail';
import ballGif from './assets/BALL.gif';
import './App.css';

function App({ client }) {
  const [allItems, setAllItems] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [checkoutId, setCheckoutId] = useState(null);
  const [firstTimeVisitor, setFirstTimeVisitor] = useState(true);
  const [cookies, setCookies, removeCookies] = useCookies([]);
  const [cartHasItems, setCartHasItems] = useState(false);
  const [firstTimeOpacity, setFirstTimeOpactity] = useState(1);

  useEffect(() => {
    async function fetchItems() {
      let homepage = await client.collection.fetchByHandle('frontpage');
      let homepageCollection = await client.collection.fetchWithProducts(homepage.id, { productsFirst : 250 });
      setAllItems(homepageCollection.products);
    }
    fetchItems();

    let myCartId = cookies.cart;
    async function createCheckout() {
      let userCheckout = await client.checkout.create();
      setCheckout(userCheckout);
      setCheckoutId(userCheckout.id);
      let date = new Date();
      date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
      setCookies('cart', userCheckout.id, { expires: date });
    }
    if (myCartId) {
      setCheckoutId(cookies.cart);
      client.checkout.fetch(myCartId).then(existingCheckout => {
        setCheckout(existingCheckout);
      });
    } else {
      createCheckout();
    }
    let firstTimeVisit = cookies['first-time-visit'];
    if (firstTimeVisit === "false") {
      setFirstTimeVisitor(false);
    }
  }, [client, cookies, cookies.cart, setCookies]);

  useEffect(() => {
    if (checkout && checkout.lineItems && checkout.lineItems.length > 0) {
      setCartHasItems(true);
    } else {
      setCartHasItems(false);
    }
    
    if (checkout && checkout.completedAt) {
      removeCookies('cart');
    }
  }, [checkout, removeCookies]);

  function handleCheckout(co) {
    setCheckout(co);
  }

  function handleFirstTimeVisit() {
    setFirstTimeOpactity(0);
    let date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    setCookies('first-time-visit', "false", { expires: date });
    setTimeout(() => {
      setFirstTimeVisitor(false)
    }, 200);
  }

  // if (firstTimeVisitor) {
  //   return (
  //     <div onClick={() => handleFirstTimeVisit()} style={{ opacity: firstTimeOpacity }} className="first-time-container">
  //     {/* // <div style={{ cursor: 'default' }} className="first-time-container"> */}
  //       <img src={ballGif} alt="basketball" />
  //     </div>
  //   );
  // } else {
    return (
      <Router>
        <div className="app-container">
          <Nav cartHasItems={cartHasItems} />
          <div>
            <Route exact path="/" render={props => <Home {...props} />} />
            <Route exact path="/products" render={props => <ItemsList {...props} allItems={allItems} />} />
            <Route
              path="/product/:id"
              render={props => (
                <ItemDetail
                  {...props}
                  client={client}
                  checkout={checkout}
                  checkoutId={checkoutId}
                  handleCheckout={handleCheckout}
                />
              )}
            />
            <Route path="/info" component={Info} />
            <Route
              path="/cart"
              render={props => (
                <Cart
                  {...props}
                  client={client}
                  checkout={checkout}
                  checkoutId={checkoutId}
                  handleCheckout={handleCheckout}
                />
              )}
            />
          </div>
        </div>
        <Footer />
      </Router>
    );
  // }

  
}

export default App;
