import React, { useState, useEffect } from 'react';
import VariantButton from '../VariantButton';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as IconBack } from '../../assets/icon-back.svg';
import { ReactComponent as IconNext } from '../../assets/icon-next.svg';
import './index.css';

function ItemDetail({ match, client, checkout, handleCheckout }) {
  const [item, setItem] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [addToCartText, setAddToCartText] = useState('ADD TO CART');

  useEffect(() => {
    if (client) {
      client.product.fetchByHandle(match.params.id).then((product) => {
        setItem(product);
      });
    }
  }, [match, client]);

  async function addLineItem() {
    setAddToCartText('ADDING..');
    const checkoutId = checkout.id;
    const itemToAdd =
      item.variants.length === 1
        ? [{ variantId: item.variants[0].id, quantity: 1 }]
        : [{ variantId: selectedVariant, quantity: 1 }];

    let newCheckout = await client.checkout.addLineItems(checkoutId, itemToAdd);
    handleCheckout(newCheckout);
    setAddToCartText('ADDED!');
  }

  async function handleAddLineItem() {
    if (item.variants.length === 1) {
      await addLineItem();
    } else {
      if (selectedVariant) {
        await addLineItem();
      }
    }
  }

  function renderVariant() {
    // If there is only one type of variant, don't show variant buttons
    if (item.variants.length === 1) {
      return <></>;
    } else {
      return (
        <div className="variants">
          {item.variants.map((variant) => {
            let selected;
            if (selectedVariant === variant.id) {
              selected = true;
            } else {
              selected = false;
            }
            return (
              <VariantButton key={variant.id} variant={variant} selected={selected} handleSelected={handleSelected} />
            );
          })}
        </div>
      );
    }
  }

  function renderImageSection() {
    if (item.images.length === 1) {
      return <div className="image-container" style={{ backgroundImage: `url(${item.images[0].src})` }}></div>;
    } else {
      return (
        <Carousel
          emulateTouch
          transitionTime={300}
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <IconBack
                type="button"
                onClick={onClickHandler}
                title={label}
                className="detail-icon detail-icon-back"
              />
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <IconNext
                type="button"
                onClick={onClickHandler}
                title={label}
                className="detail-icon detail-icon-next"
              />
            )
          }
          dynamicHeight
        >
          {renderImages()}
        </Carousel>
      );
    }
  }

  function renderImages() {
    return item.images.map((image) => {
      return (
        <div key={image.id} className="detail-image-container">
          <img alt="item" src={image.src} />
        </div>
      );
    });
  }

  function renderAddToCart() {
    if (item.availableForSale) {
      return (
        <button onClick={() => handleAddLineItem()} className="btn">
          {addToCartText}
        </button>
      );
    } else {
      return (
        <button className="btn-sold-out">
          SOLD OUT
        </button>
      );
    }
  }

  function handleSelected(variantId) {
    setSelectedVariant(variantId);
  }

  if (!item) {
    return <div />;
  }

  return (
    <div className="detail-container">
      <div className="details">
        <div className="image-section">{renderImageSection()}</div>
        <div className="info-section">
          <div className="info-title">{item.title}</div>
          <div className="info-body">
            <div className="description" dangerouslySetInnerHTML={{ __html: item.descriptionHtml }} />
            <div className="separator" />
            <div className="price">${(Math.round(item.variants[0].price.amount * 100) / 100).toFixed(2)}</div>
          </div>
          {renderVariant()}
          <div className="button-container">
            {renderAddToCart()}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ItemDetail;
