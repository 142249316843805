import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';

function Item({ item }) {

  const renderItems = () => {
    if (item && item.images[1]) { 
      return(
        <>
          <img className="item-image-1" alt={item.title} src={item.images[0].src} />
          <img className="item-image-2" alt={item.title} src={item.images[1].src} />
        </>
      )
    } else {
      return <img alt={item.title} src={item.images[0].src} />
    }
  };

  return (
    <div className="item-container">
      <Link className="item-link-container" to={`/product/${item.handle}`}>
        <div className="item-image-container">
          <div className="item-images">
            {renderItems()}
          </div>
          <div className="item-title">{item.title}</div>
        </div>
      </Link>
      <div className="item-price">${(Math.round(item.variants[0].price.amount * 100) / 100).toFixed(2)}</div>
    </div>
  );
}
export default Item;
