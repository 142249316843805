import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/Cart Icon v2.svg';
import GUAVABABALOGO from '../../assets/CORE LOGO_v1-MAIN.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import './index.css';

function Nav({ cartHasItems }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowDimensions();

  function handleBurger() {
    window.scrollTo(0, 0);
    setIsExpanded(!isExpanded);
  }

  function renderNavItems() {
    if (width > 880) {
      return (
        <div className="nav-links">
          <ul>
            <Link to="/products">store</Link>
            <Link to="/info">info</Link>
          </ul>
          <Link className="logo" to="/cart">
            <Logo style={{ height: '22px' }} />
            {cartHasItems ? <div className="cart-dot" /> : <></>}
          </Link>
        </div>
      );
    } else {
      return (
        <Link style={{ marginRight: '24px' }} className="logo" to="/cart">
          <Logo style={{ height: '22px' }} />
          {cartHasItems ? <div className="cart-dot" /> : <></>}
        </Link>
      );
    }
  }

  function renderLinks() {
    if (width < 880) {
      return (
        <div className="links-burger">
          <ul className={`${isExpanded ? 'nav-expanded' : ''}`}>
            <Link onClick={() => setIsExpanded(false)} to="/products">
              store
            </Link>
            <Link onClick={() => setIsExpanded(false)} to="/info">
              info
            </Link>
          </ul>
        </div>
      );
    }
  }

  return (
    <div>
      <nav>
        <div onClick={() => handleBurger()} className="nav-burger">
          <div />
          <div />
          <div />
        </div>
        <Link style={{ textDecoration: 'none' }} to="/">
          <div className="nav-title">
            <img src={GUAVABABALOGO} alt="guavababa-logo" />
          </div>
        </Link>
        {renderNavItems()}
      </nav>
      {renderLinks()}
    </div>
  );
}

export default Nav;
