import React from 'react';
import { ReactComponent as Logo } from '../../assets/GB-Guy.svg';
import './index.css';

function Info() {

  return (
    <div className="info-container">
      <div className="info">
        <div className="info-col">
          <div className="info-content">
            <Logo className="info-logo" />
            <div className="info-big">
              GUAVABABA is a project by Andrew Liu
            </div>
            <div className="info-social">
              <div className="info-social-header">INSTAGRAM</div>
              <div className="info-social-text"><a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/guavababa/">@guavababa</a></div>
              <div className="info-social-header">EMAIL</div>
              <div className="info-social-text">andrew@guavababa.com</div>
            </div>
          </div>
        </div>
        <div className="info-col">
          <div className="info-content">
            <div className="info-header">SHIPPING</div>
            <div className="info-text">All orders will be shipped within 1-2 business days.</div>
            <div className="info-text">US orders are shipped via USPS First Class (1-3 business days).</div>
            <div className="info-text">International orders are shipped via USPS First Class International (5-14 business days).</div>
            
            <div className="returns">
              <div className="info-header">RETURNS & EXCHANGES</div>
              <div className="info-text">Returns are generally accepted if the item is unused and in the same condition you received it in. If it meets these standards, you will receive a refund.</div>
              <div className="info-text">If you would like to exchange an item, please reach out to <span>andrew@guavababa.com</span></div>
            </div>
          </div>
        </div>
        <div className="info-col">
          <div className="info-content policies">
            <div className="info-header">POLICIES</div>
            <div className="info-text">
              GUAVABABA has the right to cancel an order at any time. 
              Order cancellations may occur but are not limited to: lack of stock to fulfill the order, suspicion of fraud, or any discrepancies on an item's description or price. 
              We will contact you if we are unable to fulfill your order based on any of the reasons listed above. 
              If we are unable to fulfill any item(s) in your order we will refund you for the item(s).
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Info;